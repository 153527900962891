<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain game-pcdddw pcddopen ">
        <!-- 数字-->
        <div   :class="'data cZ'+(i+1)" v-for="(item,i) in rowsData" :key="item.title">
          <h3>{{ item.title }}</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" v-for="(ele,xi) in item.arr" :key="ele.ResultID">
              <ol class="td_name" style="width:35px">
             <p v-if="xi<=9" :class="'ball b'+Number(i)" style="margin:0">{{ ele.label }}</p>
             <template v-if="xi>9"><span :class="honglvlan[i]">{{ele.label}}</span> </template>
               
              </ol>
              <ol class="td_rate">
          
                {{ ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        
        
        

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'dw',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "第一球", arr: [] },
        { title: "第二球", arr: [] },
        { title: "第三球", arr: [] },
      ],
      // numArr3: [],
      // numArr2: [],
      // numArr1: [],
      honglvlan:['red','green','blue'],
      num1: 0,
      num2: 1,
      num3: 2,
      numArr: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr1: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr2: [
        "大",
        "单",
        "极大",
        "大单",
        "大双",
        "小",
        "双",
        "极小",
        "小单",
        "小双",
      ],
      //大      单   极大  大单  大双   小     双   极小   小单  小双
      lmIds: [2048, 2050, 2052, 2054, 2055, 2049, 2051, 2053, 2056, 2057],
      labelArr3: ["红波", "绿波", "蓝波", "豹子"],
      colorArr: ["red", "green", "blue", ""],
    };
  },
  computed: {
    numArr1() {
      return this.numArr.filter((num) => num != this.num2 && num != this.num3);
    },
    numArr2() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num3);
    },
    numArr3() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num2);
    },
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  watch: {
    num1: {
      immediate: true,
      handler(val) {},
    },
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                // if (ele.label == "特码包三") {
                //   obj.num = this.num1 + "," + this.num2 + "," + this.num3;
                // }
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 定位
      let oneballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      let twoballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      let threeballlabel=['0','1','2','3','4','5','6','7','8','9','大','小','单','双']
      
        //定位
       // 定位
       let arr4=this.filterData(resAArr, 21887, 21900, oneballlabel)
      this.rowsData[0].arr = arr4
      let arr5=this.filterData(resAArr, 21901, 21914, twoballlabel)
      this.rowsData[1].arr = arr5
      let arr6=this.filterData(resAArr, 21915, 21928, threeballlabel)
      this.rowsData[2].arr = arr6
     


        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.pcddopen .ball:after {
   
    content: "";
  
}
.listmain.game-pcdddw .cZ3{
  width: calc((100% - 8px) / 3);
}
.listmain.game-pcdddw .cZ1,
.listmain.game-pcdddw .cZ2{
  margin-right: 2px;
  width: calc((100% - 8px) / 3);
}
 
.listmain > .data ul.custom_ul li {
  height: 30px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
 

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data {
  width: 33.333%;
 
}
.listmain {
  display: flex;
}
</style>
